<template>
  <div>
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <b-row class="justify-content-md-center">
        <b-col>
          <h1>Welcome to Reducer</h1>
          <div v-if="!hasError">
            <Busy primary text="Loading, we're almost ready..." />
          </div>
          <div v-else>
            <p>
              Ooops! Something didn't work as expected with that link. You could go to the
              <router-link :to="{ name: 'signup' }">signup page</router-link>, or email us at
              <b-link href="mailto:support@reducer.co.uk">support@reducer.co.uk</b-link> for some
              help.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import CognitoAuth from "@/cognito/cognitoauth";
import Console from "@/console";
import { EventBus } from "@/components/eventbus";
import Busy from "@/components/Busy";
import router from "@/router";

export default {
  name: "MagicLink",
  components: {
    Busy
  },
  data() {
    return {
      title: "Magic Link",
      hasError: false
    };
  },
  async created() {
    let code = this.$route.query.code;
    if (code && code.length > 1) {
      await this.getTokens();
    } else {
      Console.log("Invalid magicCode: ", code);
      this.hasError = true;
    }
  },
  methods: {
    async getTokens() {
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}magiccodes/validateMagicAuthLink?magicCode=` +
            this.$route.query.code
        );
        Console.log(response);
        if (response.data.status === "success") {
          CognitoAuth.createUserSession(
            response.data.accessToken,
            response.data.idToken,
            response.data.refreshToken
          );
          //Sign in events causes NavBar to refresh
          EventBus.$emit("user-signed-in");
          router.push(response.data.redirect);
        } else {
          this.hasError = true;
        }
      } catch (err) {
        Console.error(err);
        this.hasError = true;
      }
    }
  }
};
</script>